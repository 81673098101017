import { baseAPICreator } from './baseAPICreator';

export const getUserPassResetByUID = async (uid: string): Promise<any | null> => {
  const api = baseAPICreator({ baseURL: '/user-pass-reset' });

  try {
    const { data } = await api.get(`/${uid}?_format=json`);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
